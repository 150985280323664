<template>
  <div>
    <redirect class="" title="Settings" route="website.settings" />
    <v-row no-gutters>
      <v-col class="page-header" cols="12">
        <h2 class="page-title">Checkout</h2>
      </v-col>
      <v-col cols="12">
        <!-- <Style />
        <v-divider class="my-5"></v-divider>
        <Footer />
        <v-divider class="my-5"></v-divider>
        <CustomerAccount />
        <v-divider class="my-5"></v-divider>
        <FormOptions />
        <v-divider class="my-5"></v-divider>
        <EmailMarketing />
        <v-divider class="my-5"></v-divider> -->
        <v-row v-if="false">
          <v-col cols="12" md="4">
            <h3>Checkout Step</h3>
          </v-col>
          <v-col cols="12" md="8" class="elevation-form">
            <h4 class="text-uppercase">Checkout Step</h4>
            <v-col cols="12">
              <v-radio-group v-model="form.checkoutStepLayout">
                <v-radio :label="`One Step`" value="1"></v-radio>
                <v-radio :label="`Three Step`" value="3"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="form.isShowShippingOptions"
                label="Show Shipping Options"
                class="option-checkbox-height"
              ></v-checkbox>
            </v-col>
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <v-row v-if="false">
          <v-col cols="12" md="4">
            <h3>Footer</h3>
          </v-col>
          <v-col cols="12" md="8" class="elevation-form">
            <quill-editor
              class="disciplineQE"
              v-model="form.checkoutFooter"
              :options="{
                theme: 'snow',
              }"
            />
          </v-col>
        </v-row>
        <v-divider class="my-5" v-if="false" />
        <Tip v-if="false" />
        <v-divider class="my-5" v-if="false" />
        <AbadonedCheckout v-if="!isLoading && false" :form="form" />
        <v-divider class="my-5" v-if="false"></v-divider>
        <AbadonedCheckoutSetting v-if="!isLoading && false" :form="form" />
        <v-divider class="my-5" v-if="false" />
        <v-row>
          <v-col cols="12" md="4">
            <h3>Theme</h3>
          </v-col>
          <v-col cols="12" md="8" class="elevation-form">
            <!-- <v-checkbox
              v-model="form.useSimpleCheckout"
              label="Use Simple Checkout"
              class="option-checkbox-height"
            ></v-checkbox> -->
            <v-select
              v-model="form.useSimpleCheckout"
              :items="checkOutStyles"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mt-5 text-end">
        <v-btn :loading="isLoading" :disabled="isDisable3 || isDisable2 || isDisable1" @click="submit" color="primary"
          >Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Style from './components/Style';
import Footer from './components/Footer';
import CustomerAccount from './components/CustomerAccount';
import FormOptions from './components/FormOptions';
import EmailMarketing from './components/EmailMarketing';
import AbadonedCheckout from './components/AbadonedCheckout';
import AbadonedCheckoutSetting from './components/AbadonedCheckoutSetting';
import Tip from './components/Tip';
import { settingsApi } from '@/apis/settings';

export default {
  components: {
    // Style,
    // Footer,
    // CustomerAccount,
    // FormOptions,
    // EmailMarketing,
    AbadonedCheckout,
    AbadonedCheckoutSetting,
    Tip,
  },
  data() {
    let defaultForm = {
      checkoutStepLayout: '3',
      checkoutFooter: '',
      isShowShippingOptions: false,
      form: {
        requireFirstName: true,
        requireCompanyName: 'string',
        requireAddressTwo: 'string',
        requireShippingPhone: 'string',
        requireAccount: 'string',
      },
      orderProcessing: {
        allowBillingAddrUsingShippingAddr: true,
        automaticFulfillLineItem: true,
        notifyShipment: true,
        automaticFulfillEvenHighRiskFraud: true,
        automaticArchiveOrder: true,
      },
      emailMarketing: {
        showSignUpOption: true,
        preselectSignUpOption: true,
      },
      abandonedCheckout: {
        useAdvancedEmailFlow: true,
        automaticSentAbandonedEmail: true,
        // onlySentToSubscribers: true,
        // sentAfter: 0,
        firstEmailScheduleTime: {
          sentAutomatically: true,
          sentAfter: 10,
          sentAfterText: 'm',
        },
        secondEmailScheduleTime: {
          sentAutomatically: true,
          sentAfter: 1,
          sentAfterText: 'h',
        },
        thirdEmailScheduleTime: {
          sentAutomatically: true,
          sentAfter: 1,
          sentAfterText: 'd',
        },
        automaticSentAbandonedSms: false,
        sentSmsToEveryone: true,
        firstSmsScheduleTime: {
          sentAutomatically: true,
          sentAfter: 10,
          sentAfterText: 'm',
        },
        secondSmsScheduleTime: {
          sentAutomatically: true,
          sentAfter: 1,
          sentAfterText: 'h',
        },
        thirdSmsScheduleTime: {
          sentAutomatically: true,
          sentAfter: 1,
          sentAfterText: 'd',
        },
      },
      orderEmail: {
        processDelay: {
          automaticSendOrderDelayEmail: true,
          sentAfter: 0,
        },
        shippingDelay: {
          automaticSendShippingDelayEmail: true,
          sentAfter: 0,
        },
      },
      checkoutLayout: 'string',
      footer: {
        showFooter: true,
      },
      tip: {
        isShow: false,
        name: 'Show your support for the team at {{shop.name}}',
        message: 'Thank you, we appreciate it.',
        options: [
          {
            id: 1,
            value: 5,
          },
          {
            id: 2,
            value: 10,
          },
          {
            id: 3,
            value: 15,
          },
        ],
      },
      useSimpleCheckout: 0,
      textMessage1: '',
      textMessage2: '',
      textMessage3: '',
    };
    return {
      isLoading: false,
      form: Object.assign({}, defaultForm),
      defaultForm,
      isDisable3: false,
      isDisable2: false,
      isDisable1: false,
      checkOutStyles: [
        {
          name: 'Green Health',
          value: 4,
        },
        {
          name: 'Onepage Plus',
          value: 5,
        },
        {
          name: 'Onepage',
          value: 6,
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async submit() {
      try {
        let res = await settingsApi.updateCheckout(this.form);
      } catch (error) {
        //
      }
    },
    async getData() {
      try {
        this.isLoading = true;
        let res = await settingsApi.getCheckout();
        if (typeof res.data === 'object') {
          this.form = Object.assign({}, res.data);
          if (!this.form.tip) this.form.tip = this.defaultForm.tip;
          if (!this.form.abandonedCheckout.firstEmailScheduleTime)
            this.form.abandonedCheckout.firstEmailScheduleTime = this.defaultForm.abandonedCheckout.firstEmailScheduleTime;
          if (!this.form.abandonedCheckout.secondEmailScheduleTime)
            this.form.abandonedCheckout.secondEmailScheduleTime = this.defaultForm.abandonedCheckout.secondEmailScheduleTime;
          if (!this.form.abandonedCheckout.thirdEmailScheduleTime)
            this.form.abandonedCheckout.thirdEmailScheduleTime = this.defaultForm.abandonedCheckout.thirdEmailScheduleTime;

          if (!this.form.abandonedCheckout.firstSmsScheduleTime)
            this.form.abandonedCheckout.firstSmsScheduleTime = this.defaultForm.abandonedCheckout.firstSmsScheduleTime;
          if (!this.form.abandonedCheckout.secondSmsScheduleTime)
            this.form.abandonedCheckout.secondSmsScheduleTime = this.defaultForm.abandonedCheckout.secondSmsScheduleTime;
          if (!this.form.abandonedCheckout.thirdSmsScheduleTime)
            this.form.abandonedCheckout.thirdSmsScheduleTime = this.defaultForm.abandonedCheckout.thirdSmsScheduleTime;
        } else {
          this.form = Object.assign({}, this.defaultForm);
        }
        console.log('useSimpleCheckout', this.form.useSimpleCheckout);
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
